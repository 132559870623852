import { __awaiter, __generator, __read, __values } from "tslib";
import { NodeHelper } from '../../helpers/NodeHelper';
import { INVISIBLE_CHARACTERS } from '../../helpers/secret';
var AttributeHandler = /** @class */ (function () {
    function AttributeHandler(properties, nodeHandler) {
        this.properties = properties;
        this.nodeHandler = nodeHandler;
    }
    AttributeHandler.prototype.handle = function (node) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, tag, attributes, attributes_1, attributes_1_1, attribute, expression, nodes, e_1_1, e_2_1;
            var e_2, _d, e_1, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 11, 12, 13]);
                        _a = __values(Object.entries(this.properties.config.tagAttributes)), _b = _a.next();
                        _f.label = 1;
                    case 1:
                        if (!!_b.done) return [3 /*break*/, 10];
                        _c = __read(_b.value, 2), tag = _c[0], attributes = _c[1];
                        _f.label = 2;
                    case 2:
                        _f.trys.push([2, 7, 8, 9]);
                        attributes_1 = (e_1 = void 0, __values(attributes)), attributes_1_1 = attributes_1.next();
                        _f.label = 3;
                    case 3:
                        if (!!attributes_1_1.done) return [3 /*break*/, 6];
                        attribute = attributes_1_1.value;
                        expression = "descendant-or-self::".concat(tag, "/@").concat(attribute, "[contains(., '").concat(INVISIBLE_CHARACTERS[0], "')]");
                        nodes = NodeHelper.evaluate(expression, node);
                        return [4 /*yield*/, this.nodeHandler.handleNodes(nodes)];
                    case 4:
                        _f.sent();
                        _f.label = 5;
                    case 5:
                        attributes_1_1 = attributes_1.next();
                        return [3 /*break*/, 3];
                    case 6: return [3 /*break*/, 9];
                    case 7:
                        e_1_1 = _f.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 9];
                    case 8:
                        try {
                            if (attributes_1_1 && !attributes_1_1.done && (_e = attributes_1.return)) _e.call(attributes_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 9:
                        _b = _a.next();
                        return [3 /*break*/, 1];
                    case 10: return [3 /*break*/, 13];
                    case 11:
                        e_2_1 = _f.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 13];
                    case 12:
                        try {
                            if (_b && !_b.done && (_d = _a.return)) _d.call(_a);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 13: return [2 /*return*/];
                }
            });
        });
    };
    return AttributeHandler;
}());
export { AttributeHandler };
