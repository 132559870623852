import { __extends } from "tslib";
var ApiHttpError = /** @class */ (function (_super) {
    __extends(ApiHttpError, _super);
    function ApiHttpError(response, code) {
        var _this = _super.call(this, 'Api http error') || this;
        _this.response = response;
        _this.code = code;
        // Set the prototype explicitly.
        Object.setPrototypeOf(_this, ApiHttpError.prototype);
        return _this;
    }
    return ApiHttpError;
}(Error));
export { ApiHttpError };
