import { __awaiter, __generator } from "tslib";
import { NodeHelper } from '../../helpers/NodeHelper';
import { INVISIBLE_CHARACTERS } from '../../helpers/secret';
var ContentHandler = /** @class */ (function () {
    function ContentHandler(nodeHandler) {
        this.nodeHandler = nodeHandler;
    }
    ContentHandler.prototype.handle = function (node) {
        return __awaiter(this, void 0, void 0, function () {
            var xPath, nodes, filtered;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        xPath = "./descendant-or-self::text()[contains(., '".concat(INVISIBLE_CHARACTERS[0], "')]");
                        nodes = NodeHelper.evaluate(xPath, node);
                        filtered = this.nodeHandler.filterRestricted(nodes);
                        return [4 /*yield*/, this.nodeHandler.handleNodes(filtered)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ContentHandler;
}());
export { ContentHandler };
