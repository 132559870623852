import { __awaiter, __generator } from "tslib";
var CoreService = /** @class */ (function () {
    function CoreService(properties, apiHttpService) {
        this.properties = properties;
        this.apiHttpService = apiHttpService;
    }
    CoreService.prototype.getLanguages = function () {
        return __awaiter(this, void 0, void 0, function () {
            var languages, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.languagePromise instanceof Promise)) {
                            this.languagePromise = this.apiHttpService.fetchJson("v2/projects/languages?size=1000");
                        }
                        _a = Set.bind;
                        return [4 /*yield*/, this.languagePromise];
                    case 1:
                        languages = new (_a.apply(Set, [void 0, (_b.sent())._embedded.languages.map(function (l) { return l.tag; })]))();
                        this.properties.preferredLanguages = new Set(Array.from(this.properties.preferredLanguages).filter(function (l) {
                            return languages.has(l);
                        }));
                        return [2 /*return*/, languages];
                }
            });
        });
    };
    CoreService.prototype.getLanguagesFull = function () {
        return __awaiter(this, void 0, void 0, function () {
            var languages;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.getLanguages();
                        return [4 /*yield*/, this.languagePromise];
                    case 1:
                        languages = _a.sent();
                        return [2 /*return*/, languages._embedded.languages];
                }
            });
        });
    };
    CoreService.prototype.getApiKeyDetails = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.apiHttpService.fetchJson("v2/api-keys/current")];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        e_1 = _a.sent();
                        // eslint-disable-next-line no-console
                        console.error(e_1);
                        // eslint-disable-next-line no-console
                        console.error('Error getting scopes. Trying to switch to production mode!');
                        this.properties.config.mode = 'production';
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CoreService.prototype.isAuthorizedTo = function (scope) {
        return this.properties.scopes.indexOf(scope) > -1;
    };
    CoreService.prototype.checkScope = function (scope) {
        if (!this.isAuthorizedTo(scope)) {
            throw new Error("Api key not permitted to do this, please add 'translations.view' scope.");
        }
    };
    CoreService.prototype.loadApiKeyDetails = function () {
        return __awaiter(this, void 0, void 0, function () {
            var details;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.properties.scopes === undefined)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getApiKeyDetails()];
                    case 1:
                        details = _a.sent();
                        this.properties.scopes = details.scopes;
                        this.properties.projectId = details.projectId;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return CoreService;
}());
export { CoreService };
