import { __read, __spreadArray } from "tslib";
import { decodeFromText, encodeMessage, removeSecrets, stringToCodePoints, } from '../../helpers/secret';
import { ValueMemory } from './ValueMemory';
var Coder = /** @class */ (function () {
    function Coder() {
        this.keyMemory = new ValueMemory();
        this.defaultMemory = new ValueMemory();
    }
    Coder.prototype.unwrap = function (text) {
        var _this = this;
        var keysAndParams = [];
        var messages = decodeFromText(text);
        messages.forEach(function (msg) {
            var _a = __read(stringToCodePoints(msg), 2), keyCode = _a[0], defaultCode = _a[1];
            var key = _this.keyMemory.numberToValue(keyCode);
            var defaultValue = defaultCode !== undefined
                ? _this.defaultMemory.numberToValue(defaultCode)
                : undefined;
            keysAndParams.push({
                key: key,
                params: undefined,
                defaultValue: defaultValue,
            });
        });
        var result = removeSecrets(text);
        if (keysAndParams.length) {
            return { text: result, keys: keysAndParams };
        }
        return undefined;
    };
    Coder.prototype.wrap = function (key, _params, defaultValue, translation) {
        if (_params === void 0) { _params = {}; }
        if (defaultValue === void 0) { defaultValue = undefined; }
        if (translation === void 0) { translation = undefined; }
        var codes = [this.keyMemory.valueToNumber(key)];
        if (defaultValue) {
            codes.push(this.defaultMemory.valueToNumber(defaultValue));
        }
        var value = translation || '';
        var invisibleMark = encodeMessage(String.fromCodePoint.apply(String, __spreadArray([], __read(codes), false)));
        return typeof value === 'string'
            ? value + invisibleMark
            : Array.isArray(value)
                ? __spreadArray(__spreadArray([], __read(value), false), [invisibleMark], false) : [value, invisibleMark];
    };
    return Coder;
}());
export { Coder };
