import { Properties } from '../Properties';
import { EventService } from './EventService';
import { ApiHttpService } from './ApiHttpService';
import { TranslationService } from './TranslationService';
import { TextService } from './TextService';
import { MouseEventHandler } from '../highlighter/MouseEventHandler';
import { TranslationHighlighter } from '../highlighter/TranslationHighlighter';
import { ElementRegistrar } from './ElementRegistrar';
import { Observer } from '../Observer';
import { CoreService } from './CoreService';
import { TolgeeConfig } from '../TolgeeConfig';
import { PluginManager } from '../toolsManager/PluginManager';
import { Messages } from '../toolsManager/Messages';
import { HighlightFunctionsInitializer } from '../highlighter/HighlightFunctionsInitializer';
import { ScreenshotService } from './ScreenshotService';
import { ModuleService } from './ModuleService';
import { TextWrapper } from '../wrappers/text/TextWrapper';
import { NodeHelper } from '../helpers/NodeHelper';
import { InvisibleWrapper } from '../wrappers/invisible/InvisibleWrapper';
var DependencyService = /** @class */ (function () {
    function DependencyService() {
        var _this = this;
        this.properties = new Properties();
        this.eventService = new EventService();
        this.apiHttpService = new ApiHttpService(this.properties);
        this.mouseEventHandler = new MouseEventHandler(this.properties);
        this.moduleService = new ModuleService();
        this.coreService = new CoreService(this.properties, this.apiHttpService);
        this.screenshotService = new ScreenshotService(this.coreService, this.apiHttpService);
        this.translationService = new TranslationService(this.properties, this.coreService, this.apiHttpService, this.eventService);
        this.textService = new TextService(this.properties, this.translationService, this.moduleService);
        this.highlightFunctionInitializer = new HighlightFunctionsInitializer(this.properties);
        this.translationHighlighter = new TranslationHighlighter(this);
        this.elementRegistrar = new ElementRegistrar(this.properties, this.translationHighlighter, this.eventService);
        this.messages = new Messages();
        this.pluginManager = new PluginManager(this.messages, this.properties, this.eventService, this.elementRegistrar, this.translationService);
        this.run = function () {
            _this.mouseEventHandler.run();
        };
        this.stop = function () {
            _this.observer.stopObserving();
            _this.elementRegistrar.cleanAll();
            NodeHelper.unmarkElementAsTargetElement(_this.properties.config.targetElement);
        };
        this.translationHighlighter.pluginManager = this.pluginManager;
    }
    DependencyService.prototype.init = function (config) {
        if (this.properties.config) {
            throw new Error('Duplicate initialization of config');
        }
        this.properties.config = new TolgeeConfig(config);
        if (this.properties.config.wrapperMode === 'invisible') {
            this.wrapper = new InvisibleWrapper(this.properties, this.elementRegistrar);
        }
        else {
            this.wrapper = new TextWrapper(this.eventService, this.properties, this.textService, this.elementRegistrar);
        }
        this.observer = new Observer(this.properties, this.wrapper, this.elementRegistrar);
        this.translationService.initStatic();
    };
    return DependencyService;
}());
export { DependencyService };
