import { __assign, __awaiter, __generator } from "tslib";
import { sleep } from '../helpers/sleep';
var PluginManager = /** @class */ (function () {
    function PluginManager(messages, properties, eventService, elementRegistrar, translationService) {
        var _this = this;
        this.messages = messages;
        this.properties = properties;
        this.eventService = eventService;
        this.elementRegistrar = elementRegistrar;
        this.translationService = translationService;
        this.handshakeSucceed = false;
        this.takeScreenshot = function (translationData) {
            return new Promise(function (resolve, reject) {
                _this.translationService
                    .changeTranslations(translationData)
                    .then(function (revertChange) { return __awaiter(_this, void 0, void 0, function () {
                    var cancel_1, e_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                this.highlightAllByKey(translationData.key);
                                return [4 /*yield*/, sleep(100)];
                            case 1:
                                _a.sent();
                                this.messages.send('TOLGEE_TAKE_SCREENSHOT');
                                cancel_1 = this.messages.listen('TOLGEE_SCREENSHOT_TAKEN', function (data) {
                                    _this.unhighlightAllByKey(translationData.key);
                                    resolve(data);
                                    revertChange();
                                    cancel_1();
                                });
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                revertChange();
                                reject(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); })
                    .catch(function (e) {
                    reject(e);
                });
            });
        };
        this.handshake = function () {
            var sharedConfiguration = __assign(__assign({}, _this.properties), { config: __assign(__assign({}, _this.properties.config), { 
                    //remove properties, which cannot be sent by window.postMessage
                    staticData: undefined, targetElement: undefined, _targetElement: undefined, ui: undefined }), uiPresent: Boolean(_this.properties.config.ui), uiVersion: process.env.TOLGEE_UI_VERSION });
            var timer = null;
            var ping = function () {
                _this.messages.send('TOLGEE_READY', sharedConfiguration);
            };
            var finish = function () {
                clearInterval(timer);
            };
            _this.messages.listen('TOLGEE_PLUGIN_READY', function () {
                _this.handshakeSucceed = true;
                _this.messages.send('TOLGEE_READY', sharedConfiguration);
                finish();
            });
            ping();
            // try ping 5 times as sometimes extension doesn't respond right away
            var counter = 0;
            timer = setInterval(function () {
                if (!_this.handshakeSucceed && counter < 5) {
                    ping();
                    counter += 1;
                }
                else {
                    finish();
                }
            }, 200);
        };
    }
    PluginManager.prototype.run = function () {
        try {
            this.messages.startListening();
            this.handshake();
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
            // eslint-disable-next-line no-console
            console.warn('Can not start communication with browser plugin. Check waning above.');
        }
    };
    PluginManager.prototype.stop = function () {
        this.messages.stopListening();
    };
    PluginManager.prototype.highlightAllByKey = function (key) {
        this.elementRegistrar
            .findAllByKey(key)
            .forEach(function (el) { return el._tolgee.highlight(); });
    };
    PluginManager.prototype.unhighlightAllByKey = function (key) {
        this.elementRegistrar
            .findAllByKey(key)
            .forEach(function (el) { return el._tolgee.unhighlight(); });
    };
    return PluginManager;
}());
export { PluginManager };
