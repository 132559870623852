import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import { ApiHttpError } from '../Errors/ApiHttpError';
import { TranslationData, } from '../types/DTOs';
var TranslationService = /** @class */ (function () {
    function TranslationService(properties, coreService, apiHttpService, eventService) {
        var _this = this;
        this.properties = properties;
        this.coreService = coreService;
        this.apiHttpService = apiHttpService;
        this.eventService = eventService;
        this.translationsCache = new Map();
        this.fetchPromises = {};
        // we need to distinguish which languages are in cache initially
        // because we need to refetch them in dev mode
        this.fetchedDev = {};
        this.updateTranslationInCache = function (data) { return __awaiter(_this, void 0, void 0, function () {
            var result;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = {};
                        Object.entries(data.translations).forEach(function (_a) {
                            var _b = __read(_a, 2), lang = _b[0], translation = _b[1];
                            var cachedData = _this.translationsCache.get(lang);
                            if (cachedData) {
                                cachedData[data.name] = translation.text;
                            }
                            result[lang] = translation.text;
                        });
                        return [4 /*yield*/, this.eventService.TRANSLATION_CHANGED.emit(new TranslationData(data.name, result, data.id))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.getTranslationsOfKey = function (key, languages) {
            if (languages === void 0) { languages = new Set([_this.properties.currentLanguage]); }
            return __awaiter(_this, void 0, void 0, function () {
                var languagesArray, languagesQuery, data, translationData_1, firstItem, langs, e_1, _a;
                var _b, _c, _d;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            this.coreService.checkScope('translations.view');
                            _e.label = 1;
                        case 1:
                            _e.trys.push([1, 3, , 7]);
                            languagesArray = __spreadArray([], __read(languages), false);
                            languagesQuery = languagesArray
                                .map(function (l) { return "languages=".concat(l); })
                                .join('&');
                            return [4 /*yield*/, this.apiHttpService.fetchJson("v2/projects/translations?".concat(languagesQuery, "&filterKeyName=").concat(encodeURIComponent(key)))];
                        case 2:
                            data = _e.sent();
                            translationData_1 = languagesArray.reduce(function (acc, curr) {
                                var _a;
                                return (__assign(__assign({}, acc), (_a = {}, _a[curr] = '', _a)));
                            }, {});
                            firstItem = (_c = (_b = data._embedded) === null || _b === void 0 ? void 0 : _b.keys) === null || _c === void 0 ? void 0 : _c[0];
                            if (firstItem === null || firstItem === void 0 ? void 0 : firstItem.translations) {
                                Object.entries(firstItem.translations).forEach(function (_a) {
                                    var _b = __read(_a, 2), language = _b[0], translation = _b[1];
                                    return (translationData_1[language] = translation.text);
                                });
                            }
                            langs = (_d = data.selectedLanguages) === null || _d === void 0 ? void 0 : _d.map(function (l) { return l.tag; });
                            return [2 /*return*/, [firstItem, langs]];
                        case 3:
                            e_1 = _e.sent();
                            if (!(e_1 instanceof ApiHttpError &&
                                e_1.response.status === 404 &&
                                e_1.code === 'language_not_found')) return [3 /*break*/, 5];
                            // only possible reason for this error is, that languages definition
                            // is changed, but the old value is stored in preferred languages
                            _a = this.properties;
                            return [4 /*yield*/, this.coreService.getLanguages()];
                        case 4:
                            // only possible reason for this error is, that languages definition
                            // is changed, but the old value is stored in preferred languages
                            _a.preferredLanguages =
                                _e.sent();
                            // eslint-disable-next-line no-console
                            console.error('Requested language not found, refreshing the page!');
                            location.reload();
                            return [3 /*break*/, 6];
                        case 5: throw e_1;
                        case 6: return [3 /*break*/, 7];
                        case 7: return [2 /*return*/];
                    }
                });
            });
        };
    }
    TranslationService.translationByValue = function (message, defaultValue) {
        if (message) {
            return message;
        }
        if (defaultValue) {
            return defaultValue;
        }
        return undefined;
    };
    TranslationService.prototype.initStatic = function () {
        var _this = this;
        var _a;
        if (typeof ((_a = this.properties.config) === null || _a === void 0 ? void 0 : _a.staticData) === 'object') {
            Object.entries(this.properties.config.staticData).forEach(function (_a) {
                var _b = __read(_a, 2), language = _b[0], data = _b[1];
                //if not provider or promise then it is raw data
                if (typeof data !== 'function') {
                    _this.setLanguageData(language, data);
                }
            });
        }
    };
    TranslationService.prototype.getCachedTranslations = function () {
        return this.translationsCache;
    };
    TranslationService.prototype.loadTranslations = function (lang) {
        if (lang === void 0) { lang = this.properties.currentLanguage; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isFetchNeeded(lang)) return [3 /*break*/, 2];
                        if (!(this.fetchPromises[lang] instanceof Promise)) {
                            this.fetchPromises[lang] = this.fetchTranslations(lang);
                        }
                        return [4 /*yield*/, this.fetchPromises[lang]];
                    case 1:
                        _a.sent();
                        this.eventService.LANGUAGE_LOADED.emit(lang);
                        _a.label = 2;
                    case 2:
                        this.fetchPromises[lang] = undefined;
                        return [2 /*return*/, this.translationsCache.get(lang)];
                }
            });
        });
    };
    TranslationService.prototype.getTranslation = function (key, lang, defaultValue) {
        if (lang === void 0) { lang = this.properties.currentLanguage; }
        return __awaiter(this, void 0, void 0, function () {
            var message, fallbackLang;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isFetchNeeded(lang)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadTranslations(lang)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        message = this.getFromCache(key, lang);
                        if (!!message) return [3 /*break*/, 5];
                        fallbackLang = this.properties.config.fallbackLanguage;
                        if (!this.isFetchNeeded(fallbackLang)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.loadTranslations(this.properties.config.fallbackLanguage)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        message = this.getFromCache(key, this.properties.config.fallbackLanguage);
                        _a.label = 5;
                    case 5: return [2 /*return*/, TranslationService.translationByValue(message, defaultValue)];
                }
            });
        });
    };
    TranslationService.prototype.updateKeyComplex = function (id, data) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.coreService.checkScope('translations.edit');
                        return [4 /*yield*/, this.apiHttpService.postJson("v2/projects/keys/".concat(id, "/complex-update"), __assign(__assign({}, data), { screenshotUploadedImageIds: ((_a = data.screenshotUploadedImageIds) === null || _a === void 0 ? void 0 : _a.length)
                                    ? data.screenshotUploadedImageIds
                                    : undefined, screenshotIdsToDelete: ((_b = data.screenshotIdsToDelete) === null || _b === void 0 ? void 0 : _b.length)
                                    ? data.screenshotIdsToDelete
                                    : undefined }), { method: 'put' })];
                    case 1:
                        result = (_c.sent());
                        return [4 /*yield*/, this.updateTranslationInCache(result)];
                    case 2:
                        _c.sent();
                        return [2 /*return*/, result];
                }
            });
        });
    };
    TranslationService.prototype.createKey = function (data) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.coreService.checkScope('keys.edit');
                        return [4 /*yield*/, this.apiHttpService.postJson("v2/projects/keys/create", __assign(__assign({}, data), { screenshotUploadedImageIds: ((_a = data.screenshotUploadedImageIds) === null || _a === void 0 ? void 0 : _a.length)
                                    ? data.screenshotUploadedImageIds
                                    : undefined }))];
                    case 1:
                        result = (_b.sent());
                        return [4 /*yield*/, this.updateTranslationInCache(result)];
                    case 2:
                        _b.sent();
                        return [2 /*return*/, result];
                }
            });
        });
    };
    TranslationService.prototype.setTranslations = function (translationData) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.coreService.checkScope('translations.edit');
                        return [4 /*yield*/, this.apiHttpService.postJson('v2/projects/translations', translationData)];
                    case 1:
                        result = (_a.sent());
                        return [4 /*yield*/, this.updateTranslationInCache({
                                id: result.keyId,
                                name: result.keyName,
                                translations: result.translations,
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, result];
                }
            });
        });
    };
    /**
     * Change translations of some keys to some value temporarily.
     * For screenshot taking with provided values, before actually saving
     * the values
     *
     * @return Returns callback changing affected translations back
     */
    TranslationService.prototype.changeTranslations = function (_a) {
        var key = _a.key, translations = _a.translations;
        return __awaiter(this, void 0, void 0, function () {
            var old;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        old = {};
                        Object.entries(translations).forEach(function (_a) {
                            var _b = __read(_a, 2), language = _b[0], value = _b[1];
                            var data = _this.translationsCache.get(language);
                            if (data) {
                                old[language] = data[key];
                                data[key] = value;
                            }
                        });
                        return [4 /*yield*/, this.eventService.TRANSLATION_CHANGED.emit({
                                key: key,
                                translations: translations,
                            })];
                    case 1:
                        _b.sent();
                        // callback to revert the operation
                        return [2 /*return*/, function () { return __awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            Object.entries(old).forEach(function (_a) {
                                                var _b = __read(_a, 2), language = _b[0], value = _b[1];
                                                var data = _this.translationsCache.get(language);
                                                if (data) {
                                                    data[key] = value;
                                                }
                                            });
                                            return [4 /*yield*/, this.eventService
                                                    .TRANSLATION_CHANGED.emit({
                                                    key: key,
                                                    translations: old,
                                                })];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }];
                }
            });
        });
    };
    TranslationService.prototype.getFromCacheOrFallback = function (key, lang, defaultValue) {
        if (lang === void 0) { lang = this.properties.currentLanguage; }
        var message = this.getFromCache(key, lang) ||
            this.getFromCache(key, this.properties.config.fallbackLanguage);
        return TranslationService.translationByValue(message, defaultValue);
    };
    TranslationService.prototype.isFetchNeeded = function (lang) {
        var isDevMode = this.properties.config.mode === 'development';
        var dataPresent = this.translationsCache.get(lang) !== undefined;
        var devFetched = Boolean(this.fetchedDev[lang]);
        return (isDevMode && !devFetched) || !dataPresent;
    };
    TranslationService.prototype.fetchTranslations = function (lang) {
        return __awaiter(this, void 0, void 0, function () {
            var isDevMode;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isDevMode = this.properties.config.mode === 'development';
                        if (!isDevMode) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fetchTranslationsDevelopment(lang)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.fetchTranslationsProduction(lang)];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    TranslationService.prototype.fetchTranslationsProduction = function (language) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var langStaticData, data, url, result, data, e_2, e_3;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        langStaticData = (_b = (_a = this.properties.config) === null || _a === void 0 ? void 0 : _a.staticData) === null || _b === void 0 ? void 0 : _b[language];
                        if (!(typeof langStaticData === 'function')) return [3 /*break*/, 2];
                        return [4 /*yield*/, langStaticData()];
                    case 1:
                        data = _c.sent();
                        this.setLanguageData(language, data);
                        return [2 /*return*/];
                    case 2:
                        if (langStaticData !== undefined) {
                            this.setLanguageData(language, langStaticData);
                            return [2 /*return*/];
                        }
                        _c.label = 3;
                    case 3:
                        url = "".concat(this.properties.config.filesUrlPrefix || '/').concat(language, ".json");
                        _c.label = 4;
                    case 4:
                        _c.trys.push([4, 10, , 11]);
                        return [4 /*yield*/, fetch(url)];
                    case 5:
                        result = _c.sent();
                        if (result.status >= 400) {
                            //on error set language data as empty object to not break the flow
                            // eslint-disable-next-line no-console
                            console.error('Server responded with error status while loading localization data.');
                            this.setLanguageData(language, {});
                            return [2 /*return*/];
                        }
                        _c.label = 6;
                    case 6:
                        _c.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, result.json()];
                    case 7:
                        data = _c.sent();
                        this.setLanguageData(language, data);
                        return [3 /*break*/, 9];
                    case 8:
                        e_2 = _c.sent();
                        // eslint-disable-next-line no-console
                        console.error("Error parsing json retrieved from ".concat(url, "."));
                        this.setEmptyLanguageData(language);
                        return [3 /*break*/, 9];
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        e_3 = _c.sent();
                        // eslint-disable-next-line no-console
                        console.error("Error fetching localization data from ".concat(url, "."));
                        this.setEmptyLanguageData(language);
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    TranslationService.prototype.fetchTranslationsDevelopment = function (language) {
        return __awaiter(this, void 0, void 0, function () {
            var data, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.coreService.loadApiKeyDetails()];
                    case 1:
                        _a.sent();
                        this.coreService.checkScope('translations.view');
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.apiHttpService.fetchJson("v2/projects/translations/".concat(language))];
                    case 3:
                        data = _a.sent();
                        this.fetchedDev[language] = true;
                        this.setLanguageData(language, data[language] || {});
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _a.sent();
                        // eslint-disable-next-line no-console
                        console.error('Error while fetching localization data from API.', e_4);
                        this.setEmptyLanguageData(language);
                        return [2 /*return*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    TranslationService.prototype.setEmptyLanguageData = function (language) {
        this.translationsCache.set(language, {});
    };
    TranslationService.prototype.setLanguageData = function (language, data) {
        // recursively walk the tree and make it flat, when tree data are provided
        var makeFlat = function (data) {
            var result = {};
            Object.entries(data).forEach(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                // ignore falsy values
                if (!value) {
                    return;
                }
                if (typeof value === 'object') {
                    Object.entries(makeFlat(value)).forEach(function (_a) {
                        var _b = __read(_a, 2), flatKey = _b[0], flatValue = _b[1];
                        result[key + '.' + flatKey] = flatValue;
                    });
                    return;
                }
                result[key] = value;
            });
            return result;
        };
        this.translationsCache.set(language, makeFlat(data));
    };
    TranslationService.prototype.getFromCache = function (key, lang) {
        if (lang === void 0) { lang = this.properties.currentLanguage; }
        var root = this.translationsCache.get(lang);
        //if lang is not downloaded or does not exist at all
        if (root === undefined) {
            return undefined;
        }
        return root[key];
    };
    return TranslationService;
}());
export { TranslationService };
