import { __values } from "tslib";
import { Encoder, Decoder } from './encoderPolyfill';
export var INVISIBLE_CHARACTERS = ['\u200C', '\u200D'];
export var INVISIBLE_REGEX = RegExp("([".concat(INVISIBLE_CHARACTERS.join(''), "]{9})+"), 'gu');
var toBytes = function (text) {
    return Array.from(new Encoder().encode(text));
};
var fromBytes = function (bytes) {
    return new Decoder().decode(new Uint8Array(bytes));
};
var padToWholeBytes = function (binary) {
    var needsToAdd = 8 - binary.length;
    return '0'.repeat(needsToAdd) + binary;
};
export var encodeMessage = function (text) {
    var bytes = toBytes(text).map(Number);
    var binary = bytes
        .map(function (byte) { return padToWholeBytes(byte.toString(2)) + '0'; })
        .join('');
    var result = Array.from(binary)
        .map(function (b) { return INVISIBLE_CHARACTERS[Number(b)]; })
        .join('');
    return result;
};
var decodeMessage = function (message) {
    var binary = Array.from(message)
        .map(function (character) {
        return INVISIBLE_CHARACTERS.indexOf(character);
    })
        .map(String)
        .join('');
    var textBytes = binary.match(/(.{9})/g);
    var codes = Uint8Array.from(textBytes.map(function (byte) { return parseInt(byte.slice(0, 8), 2); }));
    return fromBytes(codes);
};
export var decodeFromText = function (text) {
    var _a;
    var invisibleMessages = (_a = text
        .match(INVISIBLE_REGEX)) === null || _a === void 0 ? void 0 : _a.filter(function (m) { return m.length > 8; });
    return (invisibleMessages === null || invisibleMessages === void 0 ? void 0 : invisibleMessages.map(decodeMessage)) || [];
};
export var removeSecrets = function (text) {
    return text.replace(INVISIBLE_REGEX, '');
};
export var stringToCodePoints = function (text) {
    var e_1, _a;
    var result = [];
    try {
        for (var text_1 = __values(text), text_1_1 = text_1.next(); !text_1_1.done; text_1_1 = text_1.next()) {
            var codePoint = text_1_1.value;
            result.push(codePoint.codePointAt(0));
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (text_1_1 && !text_1_1.done && (_a = text_1.return)) _a.call(text_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return result;
};
