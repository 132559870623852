import { __awaiter, __generator } from "tslib";
var TextService = /** @class */ (function () {
    function TextService(properties, translationService, moduleService) {
        var _this = this;
        this.properties = properties;
        this.translationService = translationService;
        this.moduleService = moduleService;
        this.format = function (translation, params, lang) {
            try {
                // try to format the text
                var result = translation;
                if (_this.moduleService.formatter) {
                    result = _this.moduleService.formatter.format({
                        translation: result,
                        params: params,
                        language: lang || _this.properties.currentLanguage,
                    });
                }
                return result;
            }
            catch (e) {
                // if string cannot be formatted, throw error
                // eslint-disable-next-line no-console
                console.error(e);
            }
        };
    }
    TextService.prototype.translate = function (key, params, lang, orEmpty, defaultValue) {
        if (lang === void 0) { lang = this.properties.currentLanguage; }
        return __awaiter(this, void 0, void 0, function () {
            var translation;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.translationService.getTranslation(key, lang, defaultValue)];
                    case 1:
                        translation = _a.sent();
                        return [2 /*return*/, this.formatTranslation(key, translation, params, lang, orEmpty)];
                }
            });
        });
    };
    TextService.prototype.instant = function (key, params, lang, orEmpty, defaultValue) {
        if (lang === void 0) { lang = this.properties.currentLanguage; }
        var translation = this.translationService.getFromCacheOrFallback(key, lang, defaultValue);
        return this.formatTranslation(key, translation, params, lang, orEmpty);
    };
    TextService.prototype.formatTranslation = function (key, translation, params, lang, orEmpty) {
        if (translation !== undefined) {
            return this.format(translation, params, lang);
        }
        if (!orEmpty) {
            return key;
        }
        return '';
    };
    return TextService;
}());
export { TextService };
