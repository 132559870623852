import { EventEmitterImpl } from './EventEmitter';
var EventService = /** @class */ (function () {
    function EventService() {
        this.TRANSLATION_CHANGED = new EventEmitterImpl();
        this.LANGUAGE_CHANGED = new EventEmitterImpl();
        this.LANGUAGE_LOADED = new EventEmitterImpl();
        this.ELEMENT_REGISTERED = new EventEmitterImpl();
    }
    return EventService;
}());
export { EventService };
