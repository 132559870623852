import { NodeHelper } from './helpers/NodeHelper';
import { ModifierKey } from './Constants/ModifierKey';
var API_KEY_LOCAL_STORAGE = '__tolgee_apiKey';
var API_URL_LOCAL_STORAGE = '__tolgee_apiUrl';
var DEFAULT_TARGET_ELEMENT_SUPPLIER = function () {
    if (typeof document !== 'undefined') {
        return document.body;
    }
};
var TolgeeConfig = /** @class */ (function () {
    function TolgeeConfig(config) {
        this.inputPrefix = '%-%tolgee:';
        this.inputSuffix = '%-%';
        this.defaultLanguage = 'en';
        this.filesUrlPrefix = 'i18n/';
        this.tagAttributes = {
            textarea: ['placeholder'],
            input: ['value', 'placeholder'],
            img: ['alt'],
            '*': ['aria-label', 'title'],
        };
        this.highlightKeys = [ModifierKey.Alt];
        this.passToParent = ['option', 'optgroup'];
        this.restrictedElements = ['script', 'style'];
        this.highlightColor = 'rgb(224 240 255)';
        this.wrapperMode = 'text';
        /**
         * When true, fallback language will be preloaded on Tolgee.run
         */
        this.preloadFallback = false;
        //workaround for: https://stackoverflow.com/questions/48725916/typescript-optional-property-with-a-getter
        Object.defineProperty(this, 'targetElement', {
            set: function (targetElement) {
                if (this.targetElement !== undefined) {
                    throw new Error('Target element is already defined!');
                }
                if (targetElement === undefined) {
                    this._targetElement = DEFAULT_TARGET_ELEMENT_SUPPLIER();
                }
                if (NodeHelper.isElementTargetElement(targetElement)) {
                    // eslint-disable-next-line no-console
                    console.error('Target element: ', this._targetElement);
                    throw new Error('An tolgee instance is inited with provided target element');
                }
                this._targetElement = targetElement;
                NodeHelper.markElementAsTargetElement(this._targetElement);
            },
            get: function () {
                return this._targetElement;
            },
        });
        Object.assign(this, config || {});
        if (typeof sessionStorage !== 'undefined') {
            this.apiUrl =
                sessionStorage.getItem(API_URL_LOCAL_STORAGE) || this.apiUrl;
            this.apiKey =
                sessionStorage.getItem(API_KEY_LOCAL_STORAGE) || this.apiKey;
        }
        if (this._targetElement === undefined) {
            this._targetElement = DEFAULT_TARGET_ELEMENT_SUPPLIER();
        }
        this.mode = this.mode || (this.apiKey ? 'development' : 'production');
        this.fallbackLanguage = this.fallbackLanguage || this.defaultLanguage;
        if (this.watch === undefined) {
            this.watch = this.mode === 'development';
        }
        if (this.availableLanguages === undefined && this.staticData) {
            this.availableLanguages = Object.keys(this.staticData);
        }
    }
    return TolgeeConfig;
}());
export { TolgeeConfig };
