var ValueMemory = /** @class */ (function () {
    function ValueMemory() {
        this.values = [];
    }
    ValueMemory.prototype.valueToNumber = function (key) {
        var index = this.values.indexOf(key);
        if (index === -1) {
            index = this.values.length;
            this.values.push(key);
        }
        return index;
    };
    ValueMemory.prototype.numberToValue = function (num) {
        return this.values[num];
    };
    return ValueMemory;
}());
export { ValueMemory };
