import { __awaiter, __generator } from "tslib";
import { DependencyService } from './services/DependencyService';
var Tolgee = /** @class */ (function () {
    function Tolgee() {
        var _this = this;
        this.stop = function () {
            _this.dependencyService.stop();
        };
        this.dependencyService = new DependencyService();
    }
    Object.defineProperty(Tolgee.prototype, "properties", {
        get: function () {
            return this.dependencyService.properties;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "lang", {
        get: function () {
            return this.properties.currentLanguage;
        },
        /**
         * This sets a new language.
         *
         * Using this setter can behave buggy when you change languages
         * too fast, since it changes the language property before
         * translations are actually loaded.
         * @deprecated use asynchronous changeLanguage method.
         */
        set: function (newLanguage) {
            var _this = this;
            this.properties.currentLanguage = newLanguage;
            this.dependencyService.translationService
                .loadTranslations(newLanguage)
                .then(function () {
                _this.emitLangChangeEvent(newLanguage);
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "defaultLanguage", {
        get: function () {
            return this.properties.config.defaultLanguage;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "onLangChange", {
        get: function () {
            return this.dependencyService.eventService.LANGUAGE_CHANGED;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "onTranslationChange", {
        get: function () {
            return this.dependencyService.eventService.TRANSLATION_CHANGED;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "onLangLoaded", {
        /**
         * Is emitted when language translations are loaded for the first time.
         * It is not emitted when language is changed and translations were loaded before.
         */
        get: function () {
            return this.dependencyService.eventService.LANGUAGE_LOADED;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "initialLoading", {
        /**
         * True if loading is needed to wait for Tolgee init.
         * When translation data are provided statically (using import
         * as staticData config property) then there is no need for translation
         * fetching so initial loading is not needed at all.
         */
        get: function () {
            var _a, _b;
            var currentLang = this.properties.currentLanguage;
            var fallbackLang = this.properties.config.fallbackLanguage;
            var fallbackPreloading = this.properties.config.preloadFallback;
            var isStaticDataProvided = function (data) {
                return data !== undefined && typeof data !== 'function';
            };
            return (!isStaticDataProvided((_a = this.properties.config.staticData) === null || _a === void 0 ? void 0 : _a[currentLang]) ||
                (!!fallbackPreloading &&
                    !isStaticDataProvided((_b = this.properties.config.staticData) === null || _b === void 0 ? void 0 : _b[fallbackLang])));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "coreService", {
        get: function () {
            return this.dependencyService.coreService;
        },
        enumerable: false,
        configurable: true
    });
    Tolgee.use = function (module) {
        return new Tolgee().use(module);
    };
    Tolgee.init = function (config) {
        return new Tolgee().init(config);
    };
    /**
     * Sets the new language.
     *
     * Emits the onLangChange and onLangChangeAndLoad events after
     * the translations are loaded.
     *
     * @return Promise<void> Resolves when translations are loaded
     */
    Tolgee.prototype.changeLanguage = function (newLanguage) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dependencyService.translationService.loadTranslations(newLanguage)];
                    case 1:
                        _a.sent();
                        this.properties.currentLanguage = newLanguage;
                        this.emitLangChangeEvent(newLanguage);
                        return [2 /*return*/];
                }
            });
        });
    };
    Tolgee.prototype.use = function (module) {
        this.dependencyService.moduleService.addModule(module);
        return this;
    };
    Tolgee.prototype.init = function (config) {
        this.dependencyService.init(config);
        return this;
    };
    Tolgee.prototype.run = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.dependencyService.run();
                        if (!(this.properties.config.mode === 'development')) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.coreService.loadApiKeyDetails()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        // eslint-disable-next-line no-console
                        console.error("Couldn't connect to tolgee");
                        // eslint-disable-next-line no-console
                        console.error(e_1);
                        this.properties.config.mode = 'production';
                        return [3 /*break*/, 4];
                    case 4: return [4 /*yield*/, this.dependencyService.translationService.loadTranslations()];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.dependencyService.pluginManager.run()];
                    case 6:
                        _a.sent();
                        if (!this.properties.config.preloadFallback) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.dependencyService.translationService.loadTranslations(this.properties.config.fallbackLanguage)];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8: return [4 /*yield*/, this.refresh()];
                    case 9:
                        _a.sent();
                        if (this.properties.config.watch) {
                            this.dependencyService.observer.observe();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Tolgee.prototype.refresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.dependencyService.wrapper.handleSubtree(this.properties.config.targetElement)];
            });
        });
    };
    Tolgee.prototype.translate = function (keyOrProps, params, noWrap, defaultValue) {
        if (params === void 0) { params = {}; }
        if (noWrap === void 0) { noWrap = false; }
        if (defaultValue === void 0) { defaultValue = undefined; }
        return __awaiter(this, void 0, void 0, function () {
            var key, orEmpty, props, translation;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        key = typeof keyOrProps === 'string' ? keyOrProps : keyOrProps.key;
                        orEmpty = undefined;
                        if (typeof keyOrProps === 'object') {
                            props = keyOrProps;
                            // if values are not provided in props object, get them from function
                            // params defaults
                            params = props.params !== undefined ? props.params : params;
                            noWrap = props.noWrap !== undefined ? props.noWrap : noWrap;
                            defaultValue =
                                props.defaultValue !== undefined ? props.defaultValue : defaultValue;
                            orEmpty = props.orEmpty;
                        }
                        return [4 /*yield*/, this.dependencyService.textService.translate(key, params, undefined, orEmpty, defaultValue)];
                    case 1:
                        translation = _a.sent();
                        if (!(this.properties.config.mode === 'development' && !noWrap)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.coreService.loadApiKeyDetails()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, this.dependencyService.wrapper.wrap(key, params, defaultValue, translation)];
                    case 3: return [2 /*return*/, translation];
                }
            });
        });
    };
    Tolgee.prototype.wrap = function (key, params, defaultValue, translation) {
        if (this.properties.config.mode === 'development') {
            return this.dependencyService.wrapper.wrap(key, params, defaultValue, translation);
        }
        else {
            return translation || defaultValue;
        }
    };
    Tolgee.prototype.instant = function (keyOrProps, params, noWrap, orEmpty, defaultValue) {
        if (params === void 0) { params = {}; }
        if (noWrap === void 0) { noWrap = false; }
        var key = typeof keyOrProps === 'string' ? keyOrProps : keyOrProps.key;
        if (typeof keyOrProps === 'object') {
            var props = keyOrProps;
            // if values are not provided in props object, get them from function
            // params defaults
            params = props.params !== undefined ? props.params : params;
            noWrap = props.noWrap !== undefined ? props.noWrap : noWrap;
            defaultValue =
                props.defaultValue !== undefined ? props.defaultValue : defaultValue;
            orEmpty = props.orEmpty !== undefined ? props.orEmpty : orEmpty;
        }
        var translation = this.dependencyService.textService.instant(key, params, undefined, orEmpty, defaultValue);
        if (this.properties.config.mode === 'development' && !noWrap) {
            return this.dependencyService.wrapper.wrap(key, params, defaultValue, translation);
        }
        return translation;
    };
    /**
     * Get currently cached translations for all languages
     */
    Tolgee.prototype.getCachedTranslations = function () {
        return this.dependencyService.translationService.getCachedTranslations();
    };
    /**
     * Loads translations for given language or returns them from cache
     * @returns Loaded translations
     */
    Tolgee.prototype.loadTranslations = function (lang) {
        return this.dependencyService.translationService.loadTranslations(lang);
    };
    Tolgee.prototype.emitLangChangeEvent = function (value) {
        var langChangedEmitter = this.onLangChange;
        langChangedEmitter.emit(value);
    };
    return Tolgee;
}());
export { Tolgee };
