var Messages = /** @class */ (function () {
    function Messages() {
        var _this = this;
        this.listeners = [];
        this.startListening = function () {
            var receiveMessage = function (event) {
                if (event.source != window) {
                    return;
                }
                _this.listeners.forEach(function (listener) {
                    if (listener.type == event.data.type) {
                        listener.callback(event.data.data);
                    }
                });
            };
            window.addEventListener('message', receiveMessage, false);
            typeof _this._stopListening === 'function' && _this._stopListening();
            _this._stopListening = function () {
                window.removeEventListener('message', receiveMessage, false);
            };
        };
        this.listen = function (type, callback) {
            var listenerInfo = { type: type, callback: callback };
            _this.listeners.push(listenerInfo);
            // return callback to remove the listener
            return function () {
                _this.listeners.splice(_this.listeners.indexOf(listenerInfo), 1);
            };
        };
        this.send = function (type, data) {
            try {
                window.postMessage({ type: type, data: data }, window.origin);
            }
            catch (e) {
                // eslint-disable-next-line no-console
                console.warn('Cannot send message.', e);
            }
        };
    }
    Messages.prototype.stopListening = function () {
        this._stopListening();
    };
    return Messages;
}());
export { Messages };
