import { __awaiter, __generator, __values } from "tslib";
var Observer = /** @class */ (function () {
    function Observer(properties, textWrapper, nodeRegistrar) {
        this.properties = properties;
        this.textWrapper = textWrapper;
        this.nodeRegistrar = nodeRegistrar;
        this._observer = undefined;
    }
    Object.defineProperty(Observer.prototype, "observer", {
        get: function () {
            var _this = this;
            if (!this._observer && typeof window !== 'undefined') {
                this._observer = new MutationObserver(function (mutationsList) { return __awaiter(_this, void 0, void 0, function () {
                    var mutationsList_1, mutationsList_1_1, mutation, e_1_1;
                    var e_1, _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 9, 10, 11]);
                                mutationsList_1 = __values(mutationsList), mutationsList_1_1 = mutationsList_1.next();
                                _b.label = 1;
                            case 1:
                                if (!!mutationsList_1_1.done) return [3 /*break*/, 8];
                                mutation = mutationsList_1_1.value;
                                if (!(mutation.type === 'characterData')) return [3 /*break*/, 3];
                                return [4 /*yield*/, this.textWrapper.handleText(mutation.target)];
                            case 2:
                                _b.sent();
                                return [3 /*break*/, 7];
                            case 3:
                                if (!(mutation.type === 'childList')) return [3 /*break*/, 5];
                                return [4 /*yield*/, this.textWrapper.handleSubtree(mutation.target)];
                            case 4:
                                _b.sent();
                                return [3 /*break*/, 7];
                            case 5:
                                if (!(mutation.type === 'attributes')) return [3 /*break*/, 7];
                                return [4 /*yield*/, this.textWrapper.handleAttribute(mutation.target)];
                            case 6:
                                _b.sent();
                                _b.label = 7;
                            case 7:
                                mutationsList_1_1 = mutationsList_1.next();
                                return [3 /*break*/, 1];
                            case 8: return [3 /*break*/, 11];
                            case 9:
                                e_1_1 = _b.sent();
                                e_1 = { error: e_1_1 };
                                return [3 /*break*/, 11];
                            case 10:
                                try {
                                    if (mutationsList_1_1 && !mutationsList_1_1.done && (_a = mutationsList_1.return)) _a.call(mutationsList_1);
                                }
                                finally { if (e_1) throw e_1.error; }
                                return [7 /*endfinally*/];
                            case 11:
                                this.nodeRegistrar.refreshAll();
                                return [2 /*return*/];
                        }
                    });
                }); });
            }
            return this._observer;
        },
        enumerable: false,
        configurable: true
    });
    Observer.prototype.observe = function () {
        if (!this.observer) {
            return;
        }
        this.observer.observe(this.properties.config.targetElement, {
            attributes: true,
            childList: true,
            subtree: true,
            characterData: true,
        });
    };
    Observer.prototype.stopObserving = function () {
        if (!this.observer) {
            return;
        }
        this.observer.disconnect();
    };
    return Observer;
}());
export { Observer };
