import { __read, __values } from "tslib";
import { TextHelper } from '../../helpers/TextHelper';
var Coder = /** @class */ (function () {
    function Coder(properties, textService) {
        this.properties = properties;
        this.textService = textService;
        this.escapeForRegExp = function (string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        };
        this.escapeParam = function (param) {
            if (typeof param === 'string') {
                return param.replace(/[,:\\]/gs, '\\$&');
            }
            if (typeof param === 'number' || typeof param === 'bigint') {
                return param.toString();
            }
            // eslint-disable-next-line no-console
            console.warn("Parameters of type \"".concat(typeof param, "\" are not supported in \"text\" wrapper mode."));
            return param;
        };
    }
    Object.defineProperty(Coder.prototype, "rawUnWrapRegex", {
        get: function () {
            var escapedPrefix = this.escapeForRegExp(this.properties.config.inputPrefix);
            var escapedSuffix = this.escapeForRegExp(this.properties.config.inputSuffix);
            return "(\\\\?)(".concat(escapedPrefix, "(.*?)").concat(escapedSuffix, ")");
        },
        enumerable: false,
        configurable: true
    });
    Coder.parseUnwrapped = function (unwrappedString) {
        var e_1, _a;
        var escaped = false;
        var actual = '';
        var paramName = '';
        var readingState = 'KEY';
        var result = {
            key: '',
            params: {},
            defaultValue: undefined,
        };
        try {
            for (var unwrappedString_1 = __values(unwrappedString), unwrappedString_1_1 = unwrappedString_1.next(); !unwrappedString_1_1.done; unwrappedString_1_1 = unwrappedString_1.next()) {
                var char = unwrappedString_1_1.value;
                if (char === '\\' && !escaped) {
                    escaped = true;
                    continue;
                }
                if (escaped) {
                    escaped = false;
                    actual += char;
                    continue;
                }
                if (readingState === 'KEY' && char === ',') {
                    readingState = 'DEFAULT_VALUE';
                    result.key = actual;
                    actual = '';
                    continue;
                }
                if (readingState === 'KEY' && char === ':') {
                    readingState = 'PARAM_NAME';
                    result.key = actual;
                    actual = '';
                    continue;
                }
                if (readingState === 'DEFAULT_VALUE' && char === ':') {
                    readingState = 'PARAM_NAME';
                    result.defaultValue = actual;
                    actual = '';
                    continue;
                }
                if (readingState === 'PARAM_NAME' && char === ':') {
                    readingState = 'PARAM_VALUE';
                    paramName = actual;
                    actual = '';
                    continue;
                }
                if (readingState === 'PARAM_VALUE' && char === ',') {
                    readingState = 'PARAM_NAME';
                    result.params[paramName] = actual;
                    actual = '';
                    continue;
                }
                actual += char;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (unwrappedString_1_1 && !unwrappedString_1_1.done && (_a = unwrappedString_1.return)) _a.call(unwrappedString_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (readingState === 'KEY') {
            result.key = actual;
        }
        if (readingState === 'DEFAULT_VALUE') {
            result.defaultValue = actual;
        }
        if (readingState === 'PARAM_VALUE') {
            result.params[paramName] = actual;
        }
        return result;
    };
    Coder.prototype.unwrap = function (text) {
        var matchRegexp = new RegExp(this.rawUnWrapRegex, 'gs');
        var keysAndParams = [];
        var matched = false;
        var match;
        var start = 0;
        var result = '';
        while ((match = matchRegexp.exec(text)) !== null) {
            var pre = match[1];
            var _a = __read(match, 4), fullMatch = _a[0], _ = _a[1], wrapped = _a[2], unwrapped = _a[3];
            var index = match.index, input = match.input;
            result += input.substr(start, index - start);
            start = index + fullMatch.length;
            if (pre === '\\') {
                if (!TextHelper.isCharEscaped(index, text)) {
                    result += wrapped;
                    continue;
                }
                pre = '';
            }
            var translated = this.getTranslatedWithMetadata(unwrapped);
            keysAndParams.push({
                key: translated.key,
                params: translated.params,
                defaultValue: translated.defaultValue,
            });
            matched = true;
            result += pre + translated.translated;
        }
        result += text.substring(start);
        if (matched) {
            return { text: result, keys: keysAndParams };
        }
        return undefined;
    };
    Coder.prototype.wrap = function (key, params, defaultValue) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (defaultValue === void 0) { defaultValue = undefined; }
        var paramString = Object.entries(params)
            .map(function (_a) {
            var _b = __read(_a, 2), name = _b[0], value = _b[1];
            return "".concat(_this.escapeParam(name), ":").concat(_this.escapeParam(value));
        })
            .join(',');
        paramString = paramString.length ? ":".concat(paramString) : '';
        var defaultString = defaultValue !== undefined ? ",".concat(this.escapeParam(defaultValue)) : '';
        return "".concat(this.properties.config.inputPrefix).concat(this.escapeParam(key)).concat(defaultString).concat(paramString).concat(this.properties.config.inputSuffix);
    };
    Coder.prototype.getTranslatedWithMetadata = function (text) {
        var _a = Coder.parseUnwrapped(text), key = _a.key, params = _a.params, defaultValue = _a.defaultValue;
        var translated = this.textService.instant(key, params, undefined, false, defaultValue);
        return { translated: translated, key: key, params: params, defaultValue: defaultValue };
    };
    return Coder;
}());
export { Coder };
