import { __awaiter, __generator } from "tslib";
import { NodeHelper } from '../../helpers/NodeHelper';
var ContentHandler = /** @class */ (function () {
    function ContentHandler(properties, nodeHandler) {
        this.properties = properties;
        this.nodeHandler = nodeHandler;
    }
    ContentHandler.prototype.handle = function (node) {
        return __awaiter(this, void 0, void 0, function () {
            var inputPrefix, inputSuffix, xPath, nodes, filtered;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        inputPrefix = this.properties.config.inputPrefix;
                        inputSuffix = this.properties.config.inputSuffix;
                        xPath = "./descendant-or-self::text()[contains(., '".concat(inputPrefix, "') and contains(., '").concat(inputSuffix, "')]");
                        nodes = NodeHelper.evaluate(xPath, node);
                        filtered = this.nodeHandler.filterRestricted(nodes);
                        return [4 /*yield*/, this.nodeHandler.handleNodes(filtered)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ContentHandler;
}());
export { ContentHandler };
