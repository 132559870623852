var TextHelper = /** @class */ (function () {
    function TextHelper() {
    }
    TextHelper.splitOnNonEscapedDelimiter = function (string, delimiter) {
        var result = [];
        var actual = '';
        var escaped = false;
        for (var i = 0; i < string.length; i++) {
            var char = string[i];
            if (char === '\\' && !escaped) {
                escaped = true;
                continue;
            }
            if (escaped) {
                escaped = false;
                actual += char;
                continue;
            }
            if (char === delimiter) {
                result.push(actual);
                actual = '';
                continue;
            }
            actual += char;
        }
        result.push(actual);
        return result;
    };
    TextHelper.isCharEscaped = function (position, fullString) {
        var escapeCharsCount = 0;
        while (position > -1 && fullString[position - 1] === '\\') {
            escapeCharsCount++;
            position--;
        }
        return escapeCharsCount % 2 == 1;
    };
    TextHelper.removeEscapes = function (string) {
        var result = '';
        var escaped = false;
        for (var i = 0; i < string.length; i++) {
            var char = string[i];
            if (char === '\\' && !escaped) {
                escaped = true;
                continue;
            }
            if (escaped) {
                escaped = false;
                result += char;
                continue;
            }
            result += char;
        }
        return result;
    };
    return TextHelper;
}());
export { TextHelper };
