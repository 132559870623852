import { __awaiter, __generator } from "tslib";
var ScreenshotService = /** @class */ (function () {
    function ScreenshotService(coreService, apiHttpService) {
        this.coreService = coreService;
        this.apiHttpService = apiHttpService;
    }
    ScreenshotService.prototype.uploadImage = function (blob) {
        return __awaiter(this, void 0, void 0, function () {
            var formData;
            return __generator(this, function (_a) {
                formData = new FormData();
                formData.append('image', blob);
                return [2 /*return*/, this.apiHttpService.postJson('v2/image-upload', undefined, {
                        headers: {},
                        body: formData,
                    })];
            });
        });
    };
    ScreenshotService.prototype.deleteImages = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiHttpService.post("v2/image-upload/".concat(ids.join(',')), undefined, {
                        method: 'delete',
                    })];
            });
        });
    };
    return ScreenshotService;
}());
export { ScreenshotService };
