var _a;
import IntlMessageFormat from 'intl-messageformat';
export var IcuFormatter = (_a = /** @class */ (function () {
        function class_1() {
            this.cache = new Map();
        }
        class_1.prototype.format = function (_a) {
            var translation = _a.translation, language = _a.language, params = _a.params;
            var ignoreTag = !Object.values(params).find(function (p) { return typeof p === 'function'; });
            return new IntlMessageFormat(translation, language, undefined, {
                ignoreTag: ignoreTag,
            }).format(params);
        };
        return class_1;
    }()),
    _a.type = 'formatter',
    _a);
