var HighlightFunctionsInitializer = /** @class */ (function () {
    function HighlightFunctionsInitializer(properties) {
        this.properties = properties;
    }
    HighlightFunctionsInitializer.prototype.initFunctions = function (element) {
        this.initHighlightFunction(element);
        this.initUnhighlightFunction(element);
    };
    HighlightFunctionsInitializer.prototype.initHighlightFunction = function (element) {
        var _this = this;
        element._tolgee.highlight = function () {
            element._tolgee.initialBackgroundColor = element.style.backgroundColor;
            element.style.backgroundColor = _this.properties.config.highlightColor;
        };
    };
    HighlightFunctionsInitializer.prototype.initUnhighlightFunction = function (element) {
        element._tolgee.unhighlight = function () {
            element.style.backgroundColor = element._tolgee.initialBackgroundColor;
        };
    };
    return HighlightFunctionsInitializer;
}());
export { HighlightFunctionsInitializer };
