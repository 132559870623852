var PREFERRED_LANGUAGES_LOCAL_STORAGE_KEY = '__tolgee_preferredLanguages';
var CURRENT_LANGUAGE_LOCAL_STORAGE_KEY = '__tolgee_currentLanguage';
var Properties = /** @class */ (function () {
    function Properties() {
    }
    Object.defineProperty(Properties.prototype, "currentLanguage", {
        get: function () {
            var result;
            if (this.config.forceLanguage) {
                return this.config.forceLanguage;
            }
            if (typeof localStorage !== 'undefined') {
                var storedLanguage = localStorage.getItem(CURRENT_LANGUAGE_LOCAL_STORAGE_KEY);
                if (storedLanguage) {
                    result = storedLanguage;
                }
                if (this.config.availableLanguages) {
                    var isSavedLanguageAvailable = this.config.availableLanguages.indexOf(result) > -1;
                    if (!isSavedLanguageAvailable) {
                        result = undefined;
                    }
                }
            }
            else {
                result = this._currentLanguage;
            }
            if (!result) {
                result = this.getLanguageByNavigator();
                this.currentLanguage = result;
            }
            return result;
        },
        set: function (language) {
            if (!language) {
                throw new Error("Setting invalid language value ".concat(language));
            }
            if (typeof localStorage === 'undefined') {
                this._currentLanguage = language;
                return;
            }
            localStorage.setItem(CURRENT_LANGUAGE_LOCAL_STORAGE_KEY, language);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Properties.prototype, "preferredLanguages", {
        get: function () {
            return new Set(JSON.parse(localStorage.getItem(PREFERRED_LANGUAGES_LOCAL_STORAGE_KEY)));
        },
        set: function (languages) {
            localStorage.setItem(PREFERRED_LANGUAGES_LOCAL_STORAGE_KEY, JSON.stringify(Array.from(languages)));
        },
        enumerable: false,
        configurable: true
    });
    Properties.prototype.getLanguageByNavigator = function () {
        if (typeof window !== 'undefined' && this.config.availableLanguages) {
            var preferred_1 = window.navigator.language;
            var exactMatch = this.config.availableLanguages.find(function (l) { return l === preferred_1; });
            if (exactMatch) {
                return exactMatch;
            }
            var getTwoLetters_1 = function (fullTag) { return fullTag.replace(/^(.+?)(-.*)?$/, '$1'); };
            var preferredTwoLetter_1 = getTwoLetters_1(window.navigator.language);
            var twoLetterMatch = this.config.availableLanguages.find(function (l) { return getTwoLetters_1(l) === preferredTwoLetter_1; });
            if (twoLetterMatch) {
                return twoLetterMatch;
            }
        }
        return this.config.defaultLanguage;
    };
    return Properties;
}());
export { Properties };
