import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import { ApiHttpError } from '../Errors/ApiHttpError';
var ApiHttpService = /** @class */ (function () {
    function ApiHttpService(properties) {
        this.properties = properties;
    }
    ApiHttpService.handleErrors = function (response) {
        return __awaiter(this, void 0, void 0, function () {
            var error, data, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(response.status >= 400)) return [3 /*break*/, 5];
                        error = new ApiHttpError(response);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        error.code = data.code;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        // eslint-disable-next-line no-console
                        console.warn('Tolgee server responded with invalid status code.');
                        return [3 /*break*/, 4];
                    case 4: throw error;
                    case 5: return [2 /*return*/, response];
                }
            });
        });
    };
    ApiHttpService.prototype.fetch = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a, url, rest;
            return __generator(this, function (_b) {
                if (typeof args[0] === 'object') {
                    return [2 /*return*/, fetch(__assign(__assign({}, args[0]), { url: this.getUrl(args[0].url) })).then(function (r) {
                            return ApiHttpService.handleErrors(r);
                        })];
                }
                _a = __read(args), url = _a[0], rest = _a.slice(1);
                return [2 /*return*/, fetch.apply(void 0, __spreadArray([this.getUrl(url)], __read(rest), false)).then(function (r) {
                        return ApiHttpService.handleErrors(r);
                    })];
            });
        });
    };
    ApiHttpService.prototype.fetchJson = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.fetch.apply(this, __spreadArray([], __read(args), false)).then(function (res) {
                        return res.json();
                    })];
            });
        });
    };
    ApiHttpService.prototype.post = function (url, body, init) {
        if (init === void 0) { init = {}; }
        var rest = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            rest[_i - 3] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.fetch.apply(this, __spreadArray([url, __assign({ body: JSON.stringify(body), method: 'POST', headers: {
                                'Content-Type': 'application/json',
                            } }, init)], __read(rest), false))];
            });
        });
    };
    ApiHttpService.prototype.postJson = function (url, body, init) {
        if (init === void 0) { init = {}; }
        var rest = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            rest[_i - 3] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.post.apply(this, __spreadArray([url, body, init], __read(rest), false)).then(function (res) { return res.json(); })];
            });
        });
    };
    ApiHttpService.prototype.getUrl = function (path) {
        var querySeparator = path.indexOf('?') < 0 ? '?' : '&';
        return "".concat(this.properties.config.apiUrl, "/").concat(path).concat(querySeparator, "ak=").concat(this.properties.config.apiKey);
    };
    return ApiHttpService;
}());
export { ApiHttpService };
