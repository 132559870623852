var Subscription = /** @class */ (function () {
    function Subscription(onUnsubscribe) {
        this.onUnsubscribe = onUnsubscribe;
    }
    Subscription.prototype.unsubscribe = function () {
        this.onUnsubscribe();
    };
    return Subscription;
}());
export { Subscription };
