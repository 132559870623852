import { __awaiter, __generator } from "tslib";
import { NodeHelper } from '../helpers/NodeHelper';
import { NodeHandler } from './NodeHandler';
import { TOLGEE_WRAPPED_ONLY_DATA_ATTRIBUTE } from '../Constants/Global';
var WrappedHandler = /** @class */ (function () {
    function WrappedHandler(elementRegistrar, nodeHandler) {
        this.elementRegistrar = elementRegistrar;
        this.nodeHandler = nodeHandler;
    }
    WrappedHandler.prototype.handle = function (node) {
        return __awaiter(this, void 0, void 0, function () {
            var xPath, nodes, filtered;
            var _this = this;
            return __generator(this, function (_a) {
                xPath = "./descendant-or-self::*[@".concat(TOLGEE_WRAPPED_ONLY_DATA_ATTRIBUTE, "]");
                nodes = NodeHelper.evaluate(xPath, node);
                filtered = this.nodeHandler.filterRestricted(nodes);
                filtered.forEach(function (element) {
                    var elementWithMeta = NodeHandler.initParentElement(element);
                    elementWithMeta._tolgee.wrappedWithElementOnlyKey = element.getAttribute(TOLGEE_WRAPPED_ONLY_DATA_ATTRIBUTE);
                    elementWithMeta._tolgee.wrappedWithElementOnlyDefaultHtml =
                        element.innerHTML;
                    _this.elementRegistrar.register(elementWithMeta);
                });
                return [2 /*return*/];
            });
        });
    };
    return WrappedHandler;
}());
export { WrappedHandler };
