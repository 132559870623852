import { __awaiter, __generator } from "tslib";
var CoreHandler = /** @class */ (function () {
    function CoreHandler(textHandler, attributeHandler, wrappedHandler) {
        this.textHandler = textHandler;
        this.attributeHandler = attributeHandler;
        this.wrappedHandler = wrappedHandler;
    }
    CoreHandler.prototype.handleSubtree = function (target) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.attributeHandler.handle(target)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.textHandler.handle(target)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.wrappedHandler.handle(target)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return CoreHandler;
}());
export { CoreHandler };
