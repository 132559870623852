import { NodeHandler } from '../NodeHandler';
import { AttributeHandler } from './AttributeHandler';
import { Coder } from './Coder';
import { CoreHandler } from './CoreHandler';
import { ContentHandler } from './ContentHandler';
import { WrappedHandler } from '../WrappedHandler';
var InvisibleWrapper = /** @class */ (function () {
    function InvisibleWrapper(properties, elementRegistrar) {
        this.coder = new Coder();
        this.nodeHandler = new NodeHandler(properties, elementRegistrar, this);
        this.textHandler = new ContentHandler(this.nodeHandler);
        this.attributeHandler = new AttributeHandler(properties, this.nodeHandler);
        this.wrappedHandler = new WrappedHandler(elementRegistrar, this.nodeHandler);
        this.coreHandler = new CoreHandler(this.textHandler, this.attributeHandler, this.wrappedHandler);
    }
    InvisibleWrapper.prototype.handleText = function (node) {
        return this.textHandler.handle(node);
    };
    InvisibleWrapper.prototype.handleSubtree = function (node) {
        return this.coreHandler.handleSubtree(node);
    };
    InvisibleWrapper.prototype.handleAttribute = function (node) {
        return this.attributeHandler.handle(node);
    };
    InvisibleWrapper.prototype.wrap = function (key, params, defaultValue, translation) {
        if (params === void 0) { params = {}; }
        if (defaultValue === void 0) { defaultValue = undefined; }
        if (translation === void 0) { translation = ''; }
        return this.coder.wrap(key, params, defaultValue, translation);
    };
    InvisibleWrapper.prototype.unwrap = function (text) {
        return this.coder.unwrap(text);
    };
    return InvisibleWrapper;
}());
export { InvisibleWrapper };
