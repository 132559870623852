import { __values } from "tslib";
import { Subscription } from './Subscription';
var EventEmitterImpl = /** @class */ (function () {
    function EventEmitterImpl() {
        this.idCounter = 0;
        this._subscriptions = new Map();
    }
    Object.defineProperty(EventEmitterImpl.prototype, "subscriptions", {
        get: function () {
            return this._subscriptions;
        },
        enumerable: false,
        configurable: true
    });
    EventEmitterImpl.prototype.emit = function (data) {
        var e_1, _a;
        var promiseReturns = [];
        try {
            for (var _b = __values(this.subscriptions.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var callback = _c.value;
                var returned = callback(data);
                if (typeof (returned === null || returned === void 0 ? void 0 : returned['then']) === 'function') {
                    promiseReturns.push(returned);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (promiseReturns.length === 0) {
            return;
        }
        return new Promise(function (resolve) {
            return Promise.all(promiseReturns).then(function () { return resolve(); });
        });
    };
    EventEmitterImpl.prototype.subscribe = function (callback) {
        var _this = this;
        var newId = this.idCounter++;
        var subscription = new Subscription(function () { return _this.unsubscribe(newId); });
        this.subscriptions.set(newId, callback);
        return subscription;
    };
    EventEmitterImpl.prototype.unsubscribe = function (id) {
        var wasPresent = this._subscriptions.delete(id);
        if (!wasPresent) {
            // eslint-disable-next-line no-console
            console.warn('Event to unsubscribe was not found');
        }
    };
    return EventEmitterImpl;
}());
export { EventEmitterImpl };
