var ModuleService = /** @class */ (function () {
    function ModuleService() {
        var _this = this;
        this.formatter = null;
        this.addModule = function (module) {
            if (module.type === 'formatter') {
                var instance = new module();
                _this.formatter = instance;
            }
            else {
                throw new Error('Module with unknown type');
            }
        };
    }
    return ModuleService;
}());
export { ModuleService };
